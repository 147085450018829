import React, { Component } from 'react';

import { PageInfoHeader } from '../../PageInfoHeader/PageInfoHeader';

import './Help.scss';

class Help extends Component {
  render() {
    return (
      <div className="eds-help-page">
        <PageInfoHeader />
        <section className="border" id="helpContent">
          <br />
          <div>
            <div className="row">
              <div className="column3">
                <h5 className="c-p">FAQs:</h5>
                <br />
                <ul>
                  <li>
                    <a
                      href="https://elligodirect.atlassian.net/servicedesk/customer/portal/8/group/52/create/229"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Need to add a new IntElligo User?
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://elligodirect.atlassian.net/servicedesk/customer/portal/8/group/52/create/230"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Issues with Patient Stipends?
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://elligodirect.atlassian.net/servicedesk/customer/portal/8/group/52/create/231"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Issue with Patient Status?
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://intelligo-static-assets.s3.amazonaws.com/help/files/IntElligo-Data.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                    >
                      IntElligo Data Compliance Assessment (FAQs)
                    </a>
                  </li>
                </ul>
                <br />
              </div>
              <div className="column3">
                <h5 className="c-p">USER GUIDES:</h5>
                <br />
                <ul>
                  <li>
                    <a
                      href="https://intelligo-static-assets.s3.amazonaws.com/help/files/CRA-Review.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                    >
                      CRA Review Guide
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://intelligo-static-assets.s3.amazonaws.com/help/files/ICF-Workflow.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                    >
                      ICF Workflow
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://intelligo-static-assets.s3.amazonaws.com/help/files/PI-Worklist.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                    >
                      Investigator Worklist Guide
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://intelligo-static-assets.s3.amazonaws.com/help/files/Calendar-Navigation.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                    >
                      Using the Calendar
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://intelligo-static-assets.s3.amazonaws.com/help/files/Dashboards.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                    >
                      Using the Dashboards
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://intelligo-static-assets.s3.amazonaws.com/help/files/Prescreen-Worklist.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                    >
                      Using the Pre-Screening Worklist
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://intelligo-static-assets.s3.amazonaws.com/help/files/Scheduling-Worklist.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                    >
                      Using the Scheduling Worklist
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://intelligo-static-assets.s3.amazonaws.com/help/files/SM-Worklist.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                    >
                      Using the Study Manager Worklist
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://intelligo-static-assets.s3.amazonaws.com/help/files/Task-Management.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                    >
                      Using the Task Worklist
                    </a>
                  </li>
                </ul>
              </div>
              <div className="column3">
                <h5 className="c-p">HOW-TO GUIDES:</h5>
                <br />
                <ul>
                  <li>
                    <a
                      href="https://intelligo-static-assets.s3.amazonaws.com/help/files/Fixed-Items.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                    >
                      Adding an Expense Fixed
                    </a>
                    <br />
                  </li>
                  <li>
                    <a
                      href="https://intelligo-static-assets.s3.amazonaws.com/help/files/Encounter-Cancel.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                    >
                      Canceling an Encounter
                    </a>
                    <br />
                  </li>
                  <li>
                    <a
                      href="https://intelligo-static-assets.s3.amazonaws.com/help/files/Encounter-Close.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                    >
                      Closing an Encounter
                    </a>
                    <br />
                  </li>
                  <li>
                    <a
                      href="https://intelligo-static-assets.s3.amazonaws.com/help/files/Unscheduled-Events.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                    >
                      Handling Unscheduled Visits
                    </a>
                    <br />
                  </li>
                  <li>
                    <a
                      href="https://intelligo-static-assets.s3.amazonaws.com/help/files/Managing-Adverse-Events.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                    >
                      Managing Adverse Events
                    </a>
                    <br />
                  </li>
                  <li>
                    <a
                      href="https://intelligo-static-assets.s3.amazonaws.com/help/files/Navigating-Patient-Studies.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                    >
                      Navigating Patient Studies
                    </a>
                    <br />
                  </li>
                  <li>
                    <a
                      href="https://intelligo-static-assets.s3.amazonaws.com/help/files/Preview-Study-Source-setup.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                    >
                      Previewing IntElligo Protocol Setup
                    </a>
                    <br />
                  </li>
                  <li>
                    <a
                      href="https://intelligo-static-assets.s3.amazonaws.com/help/files/Certified-eSource-Copies.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                    >
                      Using Certified eSource Copy Feature
                    </a>
                    <br />
                  </li>
                  <li>
                    <a
                      href="https://intelligo-static-assets.s3.amazonaws.com/help/files/When_to_NP.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                    >
                      Using the ‘Not Perform' Feature
                    </a>
                    <br />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <br />
        </section>
        <section className="border" id="helpContent">
          <br />
          <div className="bottomCenter supportText">
            Still can’t find what you are looking for? Submit a{' '}
            <a
              href="https://elligodirect.atlassian.net/servicedesk/customer/portal/8"
              target="_blank"
              rel="noopener noreferrer"
            >
              Support Request
            </a>{' '}
            for our IntElligo team.
          </div>
          <br />
        </section>
        <section className="border" id="helpContent">
          <br />
          <div className="row">
            <div className="column2">
              <h5 className="c-p">Informed Consent and Audit Report</h5>
              <br />
              <video className="video" muted controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}>
                <source
                  src="https://intelligo-static-assets.s3.amazonaws.com/help/video/ICF_and_Audit_Report.mp4"
                  type="video/mp4"
                />
                Your browser does not support playing this video. Please use Chrome.
              </video>
            </div>
            <div className="column2">
              <h5 className="c-p">Granular Permissions</h5>
              <br />
              <video className="video" muted controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}>
                <source
                  src="https://intelligo-static-assets.s3.amazonaws.com/help/video/Granular_Permissions.mp4"
                  type="video/mp4"
                />
                Your browser does not support playing this video. Please use Chrome.
              </video>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="column2">
              <h5 className="c-p">CRA Review Guide</h5>
              <br />
              <video className="video" muted controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}>
                <source
                  src="https://intelligo-static-assets.s3.amazonaws.com/help/video/CRAReview.mp4"
                  type="video/mp4"
                />
                Your browser does not support playing this video. Please use Chrome.
              </video>
            </div>
            <div className="column2">
              <h5 className="c-p">Distributing Patient Stipends</h5>
              <br />
              <video className="video" muted controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}>
                <source
                  src="https://intelligo-static-assets.s3.amazonaws.com/help/video/ElligoPatientStipends.mp4"
                  type="video/mp4"
                />
                Your browser does not support playing this video. Please use Chrome.
              </video>
            </div>
          </div>
          <br />
        </section>
        <section className="border" id="helpContent">
          <br />
          <div>
            <div className="row">
              <div className="column4">
                <h5 className="c-p">Release Notes</h5>
                <br />
                <ul>
                  <li>
                    <a
                      href="https://intelligo-static-assets.s3.amazonaws.com/help/files/IntElligoFiveTen.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                    >
                      Release Notes v5.10
                    </a>{' '}
                    - (current)
                  </li>
                  <li>
                    <a
                      href="https://intelligo-static-assets.s3.amazonaws.com/help/files/IntElligoFiveNine.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                    >
                      Release Notes v5.9
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://intelligo-static-assets.s3.amazonaws.com/help/files/IntElligoFiveEight.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                    >
                      Release Notes v5.8
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://intelligo-static-assets.s3.amazonaws.com/help/files/IntElligoFiveSeven.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                    >
                      Release Notes v5.7
                    </a>
                  </li>
                </ul>
              </div>
              <div className="column4">
                <br />
                <br />
                <ul>
                  <li>
                    <a
                      href="https://intelligo-static-assets.s3.amazonaws.com/help/files/IntElligoFiveSix.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                    >
                      Release Notes v5.6
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://intelligo-static-assets.s3.amazonaws.com/help/files/IntElligoFiveFive.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                    >
                      Release Notes v5.5
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://intelligo-static-assets.s3.amazonaws.com/help/files/IntElligoFiveFour.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                    >
                      Release Notes v5.4
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://intelligo-static-assets.s3.amazonaws.com/help/files/IntElligoFiveThree.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                    >
                      Release Notes v5.3
                    </a>
                  </li>
                </ul>
              </div>
              <div className="column4">
                <br />
                <br />
                <ul>
                  <li>
                    <a
                      href="https://intelligo-static-assets.s3.amazonaws.com/help/files/IntElligoFiveTwo.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                    >
                      Release Notes v5.2
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://intelligo-static-assets.s3.amazonaws.com/help/files/IntElligoFiveOne.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                    >
                      Release Notes v5.1
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://intelligo-static-assets.s3.amazonaws.com/help/files/IntElligoFiveO.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                    >
                      Release Notes v5.0
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://intelligo-static-assets.s3.amazonaws.com/help/files/IntElligoFourNine.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                    >
                      Release Notes v4.9
                    </a>
                  </li>
                </ul>
              </div>
              <div className="column4">
                <br />
                <br />
                <ul>
                  <li>
                    <a
                      href="https://intelligo-static-assets.s3.amazonaws.com/help/files/IntElligoFourEight.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                    >
                      Release Notes v4.8
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://intelligo-static-assets.s3.amazonaws.com/help/files/IntElligoFourSeven.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                    >
                      Release Notes v4.7
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://intelligo-static-assets.s3.amazonaws.com/help/files/IntElligoFourSix.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                    >
                      Release Notes v4.6
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://intelligo-static-assets.s3.amazonaws.com/help/files/IntElligoFourFive.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                    >
                      Release Notes v4.5
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <br />
        </section>
        <section className="border" id="helpContent">
          <div className="bottomCenter termsOfUse">
            <a
              className="c-p"
              href="https://intelligo-static-assets.s3.amazonaws.com/help/files/IntElligoTermsOfUse.pdf"
              target="_blank"
              rel="noopener noreferrer"
              download
            >
              Terms of Use
            </a>
          </div>
        </section>
      </div>
    );
  }
}

export default Help;
