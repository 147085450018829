import React, { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Button } from '@mui/material';
import {
  DataGridPremium,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  useGridApiRef
} from '@mui/x-data-grid-premium';
import { isEmpty } from 'lodash';
import moment from 'moment';

import useLocalStorage from '../../../../../common/hooks/useLocalStorage';
import { dateTime12, DD_SLASH_MMM_SLASH_YYYY } from '../../../../../constants/dateFormat';
import { CLOSED } from '../../../../../constants/ssuStatuses';
import { scBlue100, scGray2 } from '../../../../../constants/systemColors';
import { MANAGE_SSU_PATIENT_STATUSES, PENDING_SCHEDULE_WORK_LIST } from '../../../../../constants/userOperations';
import { ROLE_SYSTEM_ADMINISTRATOR } from '../../../../../constants/userRoles';
import { userHasAccessTo, userHasRole } from '../../../../../services/auth';
import { generateUrlByKey } from '../../../../root/router';
import { ACTIONS_BUTTON, TABLE_CHECKBOX } from '../../../finance/NewInvoice/InvoiceTable/TableConstants';
import { DefaultFinanceCell } from '../../../finance/shared/FinanseTableMUI/DefaultFinanceCell';
import PatientStatusChange from '../PatientStatusChange';
import { ScheduleAppointmentColumn } from '../ScheduleAppointmentColumn';
import { WorklistContext } from '../WorklistContext';
import { showScheduleAppointmentPlus } from '../worklistService';

import { SelectedItemsMenu } from './SelectedItemsMenu/SelectedItemsMenu';
import { TableRowMenu } from './TableRowMenu/TableRowMenu';
import ContactAttemptDaysRemainingCell from './ContactAttemptDaysRemainingCell';
import { screeningStatuses, STABLE_COLUMNS } from './WorklistTableConstants';
import { normalizeStateForRestore, viewAttempts } from './WorklistTableServices';

import './WorklistTableMUI.scss';

export const WorklistTableMUI = () => {
  const apiRef = useGridApiRef();
  const { tableData, itemGroupAccessors, updatePage } = useContext(WorklistContext);
  const [savedState, setSavedState] = useLocalStorage('WORKLIST_TABLE', {});

  const saveSnapshot = useCallback(() => {
    if (apiRef?.current?.exportState && localStorage) {
      const currentState = apiRef.current.exportState();
      setSavedState({ ...currentState, filter: {} });
    }
  }, [apiRef, setSavedState]);

  const initialPinnedColumns = useMemo(() => {
    return !isEmpty(savedState)
      ? {
          left: savedState.pinnedColumns.left.filter(column => STABLE_COLUMNS.includes(column)),
          right: savedState.pinnedColumns.right.filter(column => STABLE_COLUMNS.includes(column))
        }
      : {
          left: [TABLE_CHECKBOX],
          right: [ACTIONS_BUTTON]
        };
  }, [savedState]);

  const initialStateValue = useMemo(() => {
    return !isEmpty(savedState)
      ? normalizeStateForRestore(savedState, STABLE_COLUMNS, Object.keys(itemGroupAccessors))
      : {
          columns: {
            columnVisibilityModel: {
              callbackRequestedDateTime: false,
              'appointment.date': false,
              scheduleAppointment: false,
              withdrawComment: false
            }
          }
        };
  }, [itemGroupAccessors, savedState]);

  const [selectedSitePatientsIds, setSelectedSitePatientsIds] = useState([]);
  const [pinnedColumns, setPinnedColumns] = useState(initialPinnedColumns);
  const [initialState, setInitialState] = useState({
    key: 0,
    value: initialStateValue
  });

  const handlePinnedColumnsChange = useCallback(updatedPinnedColumns => {
    setPinnedColumns({
      ...updatedPinnedColumns,
      left: [TABLE_CHECKBOX, ...updatedPinnedColumns.left.filter(column => column !== TABLE_CHECKBOX)],
      right: [...updatedPinnedColumns.right.filter(column => column !== ACTIONS_BUTTON), ACTIONS_BUTTON]
    });
  }, []);

  const itemGroupColumns = useMemo(
    () =>
      itemGroupAccessors && Object.keys(itemGroupAccessors).length > 0
        ? Object.keys(itemGroupAccessors).map(accessor => ({
            headerName: itemGroupAccessors[accessor],
            field: accessor,
            flex: 1,
            minWidth: 140,
            valueGetter: ({ row }) => row[accessor].itemGroupStatus,
            renderCell: ({ row }) => row[accessor].itemGroupStatus
          }))
        : [],
    [itemGroupAccessors]
  );

  const startingColumns = useMemo(
    () => [
      {
        headerName: 'Patient',
        field: 'fullName',
        flex: 1,
        minWidth: 200,
        renderCell: ({ row }) => {
          return (
            <Link
              to={generateUrlByKey('Worklist.Patient Profile.Patient Studies', {
                patientId: row.patientUniqueIdentifier,
                ssuPatientId: row.sitePatientId
              })}
            >
              {row.fullName}
              {row.preferredName && <span className="patient-preferred-name"> ({row.preferredName})</span>}
            </Link>
          );
        }
      },
      {
        headerName: 'Patient ID',
        field: 'patientId',
        flex: 1,
        minWidth: 140,
        renderCell: ({ row }) => row.patientId
      },
      {
        headerName: 'Study',
        field: 'study',
        flex: 1,
        minWidth: 200,
        valueGetter: ({ row }) => row.study.name,
        renderCell: ({ row }) => row.study.name
      },
      {
        headerName: 'Site',
        field: 'site',
        flex: 1,
        minWidth: 200,
        valueGetter: ({ row }) => row.site.name,
        renderCell: ({ row }) => row.site.name
      },
      {
        headerName: 'Patient Source',
        field: 'patientSource',
        flex: 1,
        minWidth: 170,
        renderCell: ({ row }) => row.patientSource
      },
      {
        headerName: 'Date Added',
        field: 'patientAddedToSsuDate',
        flex: 1,
        minWidth: 200,
        type: 'date',
        valueFormatter: ({ value }) => (value ? moment(value).format(DD_SLASH_MMM_SLASH_YYYY) : '—'),
        valueGetter: ({ value }) => value && new Date(value),
        renderCell: ({ row }) => moment(row.patientAddedToSsuDate).format(DD_SLASH_MMM_SLASH_YYYY)
      },
      {
        headerName: 'Patient Status',
        field: 'status',
        minWidth: 190,
        valueGetter: ({ row }) => screeningStatuses.find(status => status.code === row.status)?.name,
        renderCell: ({ row }) => {
          const isAllowedToEdit =
            userHasRole(ROLE_SYSTEM_ADMINISTRATOR) ||
            (row.studySiteStatus !== CLOSED && userHasAccessTo(MANAGE_SSU_PATIENT_STATUSES));
          return <PatientStatusChange row={row} isAllowedToEdit={isAllowedToEdit} />;
        }
      },
      {
        headerName: 'Prescreen Status',
        field: 'prescreenStatus',
        flex: 1,
        minWidth: 180,
        renderCell: ({ row }) => (row.prescreenStatus ? row.prescreenStatus : '—')
      },
      {
        headerName: 'Last Updated (Days)',
        field: 'idleTime',
        flex: 1,
        minWidth: 200,
        type: 'number',
        valueGetter: ({ value }) => (value >= 0 ? value : null),
        renderCell: ({ value }) => (value >= 0 ? value : '')
      },
      {
        headerName: 'Contact Attempt',
        field: 'contactAttempt',
        width: 185,
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ row, value }) => (
          <Button
            variant="text"
            sx={{ color: scBlue100, fontWeight: 400 }}
            onClick={() => viewAttempts(row.sitePatientId)}
          >
            {value}
          </Button>
        )
      },
      {
        headerName: 'Contact Attempt Days Remaining',
        field: 'contactAttemptsDaysRemaining',
        flex: 1,
        minWidth: 290,
        valueFormatter: ({ value }) => {
          return value === '' ? 'N/A' : value;
        },
        valueGetter: ({ row }) => {
          return row.contactAttemptDaysRemaining === null ? '' : `${row.contactAttemptDaysRemaining} Day(s)`;
        },
        renderCell: ContactAttemptDaysRemainingCell
      },
      {
        headerName: 'Prescreen Failed Date',
        field: 'prescreenFailedDate',
        flex: 1,
        minWidth: 220,
        type: 'date',
        valueFormatter: ({ value }) => (value ? moment(value).format(DD_SLASH_MMM_SLASH_YYYY) : '—'),
        valueGetter: ({ value }) => value && new Date(value),
        renderCell: ({ row }) =>
          row.prescreenFailedDate ? moment(row.prescreenFailedDate).format(DD_SLASH_MMM_SLASH_YYYY) : '—'
      },
      {
        headerName: 'Callback Request Date/Time',
        field: 'callbackRequestedDateTime',
        minWidth: 255,
        flex: 1,
        type: 'date',
        valueFormatter: ({ value }) => (value ? moment(value).format(dateTime12) : '—'),
        valueGetter: ({ row }) => row.callbackRequestedDateTime && new Date(row.callbackRequestedDateTime),
        renderCell: ({ row }) =>
          row.callbackRequestedDateTime ? moment(row.callbackRequestedDateTime).format(dateTime12) : '—'
      },
      {
        headerName: 'Prescreen Failed Note',
        field: 'withdrawComment',
        minWidth: 210,
        flex: 1,
        valueGetter: ({ row }) =>
          row.status === 'PRE_SCREEN_FAILED' && row.withdrawComment ? row.withdrawComment : '',
        renderCell: DefaultFinanceCell
      },
      {
        headerName: 'Appointment',
        field: 'appointment.date',
        minWidth: 180,
        flex: 1,
        type: 'date',
        valueFormatter: ({ value }) => (value ? moment(value).format(dateTime12) : '—'),
        valueGetter: ({ row }) => row.appointment?.date && new Date(row.appointment?.date),
        renderCell: ({ row }) => (row.appointment?.date ? moment(row.appointment?.date).format(dateTime12) : null)
      },
      {
        headerName: 'Schedule Appointments',
        minWidth: 230,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        show: userHasAccessTo(PENDING_SCHEDULE_WORK_LIST),
        field: 'scheduleAppointment',
        renderCell: ({ row }) =>
          showScheduleAppointmentPlus(row) && (
            <ScheduleAppointmentColumn
              selectedStudySites={{ id: row.studySiteIdentifier }}
              studySiteStatus={row.studySiteStatus}
              selectedStudies={row.study}
              selectedSites={row.site}
              patient={`${row.firstName} ${row.lastName}`}
            />
          )
      },
      {
        field: 'actions',
        type: 'actions',
        resizable: false,
        width: 50,
        renderCell: ({ row }) => <TableRowMenu row={row} />
      }
    ],
    []
  );

  const columns = useMemo(() => {
    return [...startingColumns, ...itemGroupColumns];
  }, [itemGroupColumns, startingColumns]);

  const getTogglableColumns = useCallback(
    columns =>
      columns.filter(column => ![ACTIONS_BUTTON, TABLE_CHECKBOX].includes(column.field)).map(column => column.field),
    []
  );

  useEffect(() => {
    if (!isEmpty(itemGroupAccessors) && !isEmpty(savedState)) {
      const dynamicColumns = Object.keys(itemGroupAccessors);
      const normalizedSavedState = normalizeStateForRestore(savedState, STABLE_COLUMNS, dynamicColumns);
      setInitialState(prevState => {
        return { key: prevState.key + 1, value: normalizedSavedState };
      });
      setPinnedColumns(normalizedSavedState.pinnedColumns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemGroupAccessors]);

  useLayoutEffect(() => {
    window.addEventListener('beforeunload', saveSnapshot);

    return () => {
      window.removeEventListener('beforeunload', saveSnapshot);
      saveSnapshot();
    };
  }, [saveSnapshot]);

  return (
    <>
      <SelectedItemsMenu
        tableData={tableData}
        selectedSitePatientsIds={selectedSitePatientsIds}
        updatePage={updatePage}
      />
      <DataGridPremium
        key={initialState.key}
        apiRef={apiRef}
        onStateChange={saveSnapshot}
        sx={{
          padding: '0 40px 0 40px',
          border: 'none',
          '.MuiDataGrid-pinnedColumns, .MuiDataGrid-pinnedColumnHeaders': {
            backgroundColor: scGray2
          }
        }}
        data-testid="worklist-table"
        getRowId={row => row?.sitePatientId}
        rows={tableData}
        columns={columns}
        rowHeight={38}
        localeText={{ noRowsLabel: 'No Record Found' }}
        disableRowSelectionOnClick
        disableRowGrouping
        disableAggregation
        slots={{
          toolbar: () => (
            <GridToolbarContainer>
              <GridToolbarColumnsButton />
              <GridToolbarFilterButton />
              <GridToolbarDensitySelector />
              <GridToolbarExport
                printOptions={{ disableToolbarButton: true }}
                excelOptions={{ disableToolbarButton: true }}
                csvOptions={{
                  escapeFormulas: false,
                  utf8WithBom: true
                }}
              />
              <Button size="small" startIcon={<RefreshIcon />} onClick={updatePage}>
                Refresh
              </Button>
              <GridToolbarQuickFilter sx={{ margin: '0 0 0 auto' }} />
            </GridToolbarContainer>
          )
        }}
        slotProps={{
          columnsPanel: {
            getTogglableColumns
          }
        }}
        initialState={initialState.value}
        checkboxSelection
        rowSelectionModel={selectedSitePatientsIds}
        onRowSelectionModelChange={newRowSelectionModel => {
          setSelectedSitePatientsIds(newRowSelectionModel);
        }}
        pinnedColumns={pinnedColumns}
        onPinnedColumnsChange={handlePinnedColumnsChange}
      />
    </>
  );
};
