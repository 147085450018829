import React, { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import { zipObject } from 'lodash/array';
import { reduce } from 'lodash/collection';
import { isEmpty } from 'lodash/lang';

import { PatientInfoApi, StudyApi } from '../../../../../../../api';
import PatientApi from '../../../../../../../api/patient/PatientApi';
import ModalBoxes, { useModalBoxes } from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../../common/general/ButtonGroup';
import NotificationManager from '../../../../../../../common/notifications/NotificationManager';
import { SOMETHING_WENT_WRONG } from '../../../../../../../constants/notificationMessages';
import { MANAGE_FINANCE_DETAILS_ACCESS } from '../../../../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../../../../services/auth';
import { onRequestError } from '../../../../../../../services/handlers';
import StudySiteStatusSourceSelect from '../StudySiteStatusSourceSelect';

import FinanceDetails from './FinanceDetails/FinanceDetails';
import GuardianDetails from './GuardianDetails/GuardianDetails';
import PatientContactDetails from './PatientContactDetails/PatientContactDetails';
import PatientDemographics from './PatientDemographics/PatientDemographics';
import PatientDetails from './PatientDetails/PatientDetails';
import { useInfo, useRequiredFields } from './AddOrEditPatientInfoModalHooks';
import {
  convertDobToDate,
  prepareDataForSave,
  prepareNewPatientInfoForSave,
  validatePatientInfo
} from './AddOrEditPatientInfoModalService';

import './AddOrEditPatientInfoModal.scss';

export default function AddOrEditPatientInfoModal({ currentStudy, modalBox, patientInfo, onSave, onDuplicate }) {
  const { setLoading } = useModalBoxes();
  const {
    info,
    isEditMode,
    validationMessages,
    setValidationMessages,
    handleCountryChange,
    handleStateChange,
    handleDateOfBirthChange,
    handleInputChange,
    handleSexChange,
    handlePronounsChange,
    handlePrimaryLanguageChange,
    handlePreferredContactMethodChange,
    handleSelectedPhoneTypeChange,
    handleHeightChange,
    handleRacesChange,
    handleEthnicitiesChange,
    resetValidationMessageByName,
    handleChangeSmsOptInDropdown,
    handleChangeInterestedInFutureResearch,
    handlePaymentTypeChange
  } = useInfo(patientInfo);

  const { address, cardId, paymentType } = info;
  const [isRequiredField] = useRequiredFields(cardId);

  const [source, setSource] = useState(null);
  const [status, setStatus] = useState(null);
  const [selectedStudy, setSelectedStudy] = useState(null);
  const [selectedSite, setSelectedSite] = useState(null);
  const [medicalRecordNumber, setMedicalRecordNumber] = useState('');
  const [userCanManageFinanceDetails, setUserCanManageFinanceDetails] = useState(true);

  const [originalPID] = useState(cloneDeep(cardId));
  const [originalPaymentType] = useState(cloneDeep(paymentType));
  const [fieldsDataDictionary, setFieldsDataDictionary] = useState(null);
  const [fieldsDataDictionaryMap, setFieldsDataDictionaryMap] = useState({});

  const { ethnicityCodes, raceCodes } = fieldsDataDictionaryMap;

  useEffect(() => {
    PatientApi.getDictionaries().then(({ data }) => {
      setFieldsDataDictionaryMap(prepareDictionaries(data));
      setFieldsDataDictionary(data);
    });
  }, []);

  useEffect(() => {
    if (userHasAccessTo(MANAGE_FINANCE_DETAILS_ACCESS) && !isEditMode) {
      setUserCanManageFinanceDetails(false);
      if (!isEmpty(selectedSite) && !isEmpty(selectedStudy))
        PatientApi.checkAbilityToAddFinanceDetails(selectedSite.uniqueIdentifier).then(({ data }) => {
          if (data) {
            setUserCanManageFinanceDetails(data);
          }
        });
    }
  }, [isEditMode, selectedSite, selectedStudy]);

  useEffect(() => {
    if (userHasAccessTo(MANAGE_FINANCE_DETAILS_ACCESS) && isEditMode) {
      setUserCanManageFinanceDetails(false);
      PatientApi.checkAbilityToEditFinanceDetails(patientInfo.id).then(({ data }) => {
        if (data) {
          setUserCanManageFinanceDetails(data);
        }
      });
    }
  }, [isEditMode, patientInfo?.id]);

  return (
    <>
      <ModalBoxes.Body>
        {!isEditMode && (
          <>
            <div className="row">
              <div className="col-12 apm-form-label">Select SSU</div>
            </div>
            <div className="col-8 top-selectors">
              <StudySiteStatusSourceSelect
                onSSUFilterChange={onSSUFilterChange}
                getValidationMessage={getValidationMessage}
                setSource={v => {
                  resetValidationMessageByName('source');
                  setSource(v);
                }}
                source={source}
                setStatus={v => {
                  resetValidationMessageByName('status');
                  setStatus(v);
                }}
                status={status}
                className="aoepim-study-site-status-source-select"
              />
            </div>
          </>
        )}
        <PatientDetails
          isEditMode={isEditMode}
          handleDateOfBirthChange={handleDateOfBirthChange}
          handleInputChange={handleInputChange}
          handleSexChange={handleSexChange}
          handlePronounsChange={handlePronounsChange}
          handlePrimaryLanguageChange={handlePrimaryLanguageChange}
          isRequiredField={isRequiredField}
          getValidationMessage={getValidationMessage}
          medicalRecordNumber={medicalRecordNumber}
          setMedicalRecordNumber={setMedicalRecordNumber}
          info={info}
          fieldsDataDictionary={fieldsDataDictionary}
        />
        <PatientContactDetails
          handleCountryChange={handleCountryChange}
          handleStateChange={handleStateChange}
          handleInputChange={handleInputChange}
          handlePreferredContactMethodChange={handlePreferredContactMethodChange}
          handleSelectedPhoneTypeChange={handleSelectedPhoneTypeChange}
          isRequiredField={isRequiredField}
          getValidationMessage={getValidationMessage}
          address={address}
          info={info}
          fieldsDataDictionary={fieldsDataDictionary}
          handleChangeSmsOptInDropdown={handleChangeSmsOptInDropdown}
          handleChangeInterestedInFutureResearch={handleChangeInterestedInFutureResearch}
        />
        <PatientDemographics
          info={info}
          isRequiredField={isRequiredField}
          getValidationMessage={getValidationMessage}
          handleInputChange={handleInputChange}
          handleHeightChange={handleHeightChange}
          handleRacesChange={handleRacesChange}
          handleEthnicitiesChange={handleEthnicitiesChange}
          ethnicityCodes={ethnicityCodes}
          raceCodes={raceCodes}
        />
        <FinanceDetails
          handleInputChange={handleInputChange}
          isRequiredField={isRequiredField}
          getValidationMessage={getValidationMessage}
          info={info}
          userCanManageFinanceDetails={userCanManageFinanceDetails}
          isEditMode={isEditMode}
          selectedStudy={selectedStudy}
          handlePaymentTypeChange={handlePaymentTypeChange}
          paymentType={originalPaymentType}
        />
        <GuardianDetails
          handleInputChange={handleInputChange}
          getValidationMessage={getValidationMessage}
          info={info}
        />
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority="medium" onClick={modalBox.close}>
            CANCEL
          </Button>
          <Button onClick={update}>SAVE</Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );

  function getValidationMessage(field) {
    return validationMessages[field];
  }

  function onSSUFilterChange(ssus, study, site) {
    if (site && site.country === 'Canada') {
      address.country = 'CA';
    } else {
      address.country = 'US';
    }
    resetValidationMessageByName('study');
    setSelectedStudy(study);
    if (site) {
      resetValidationMessageByName('site');
      const ssu = ssus.find(e => e.site.uniqueIdentifier === site.uniqueIdentifier);
      setSelectedSite(ssu);
    } else {
      setSelectedSite(null);
    }
  }

  function update() {
    const isEditMode = !!patientInfo?.id;
    const validationMessages = validatePatientInfo(
      info,
      isEditMode,
      selectedStudy,
      selectedSite,
      status,
      source,
      originalPID
    );
    setValidationMessages(validationMessages);
    if (!isEmpty(validationMessages)) {
      return;
    }
    convertDobToDate(info);
    setLoading(true);
    if (isEditMode) {
      info.changedCardId =
        Boolean(!originalPID && info.cardId) || (originalPID && info.cardId && originalPID !== info.cardId);
      if (currentStudy) {
        // if on patient studies page, use new edit check
        PatientInfoApi.updatePatientInfoWithSsu(patientInfo.id, prepareDataForSave(info), currentStudy.studyId)
          .then(function({
            data: { pidMessage, warningMessage, emailDuplicationMessage, duplicateInfo, existsInStudy, patientInfo }
          }) {
            if (emailDuplicationMessage) {
              showDuplicateEmailModal(emailDuplicationMessage);
              setLoading(false);
            } else if (!isEmpty(duplicateInfo)) {
              modalBox.close();
              onDuplicate(currentStudy, duplicateInfo, existsInStudy, {
                ...prepareDataForSave(info),
                id: patientInfo.id
              });
            } else {
              onSave(info);
              if (pidMessage !== '') {
                warningMessage ? NotificationManager.error(pidMessage) : NotificationManager.success(pidMessage);
              } else if (warningMessage) {
                NotificationManager.warning(warningMessage);
              }
            }
          })
          .catch(function(err, params) {
            onRequestError(err, params);
            setLoading(false);
          });
      } else {
        // if not, then we have no source, so cannot do an edit check the same way
        PatientInfoApi.updatePatientInfo(patientInfo.id, prepareDataForSave(info))
          .then(function({ data: { pidMessage, warningMessage, emailDuplicationMessage } }) {
            if (emailDuplicationMessage) {
              showDuplicateEmailModal(emailDuplicationMessage);
              setLoading(false);
            } else {
              onSave(info);
            }
            if (pidMessage !== '') {
              warningMessage ? NotificationManager.error(pidMessage) : NotificationManager.success(pidMessage);
            } else if (warningMessage) {
              NotificationManager.warning(warningMessage);
            }
          })
          .catch(function(err, params) {
            onRequestError(err, params);
            setLoading(false);
          });
      }
      return;
    }

    function showAddPatientStudyModal(patientData, studyName, siteName, studySites, duplicationResponse) {
      const patientName = `${duplicationResponse.patientLastName}, ${duplicationResponse.patientFirstName}`;
      const isDuplicatedFieldNameAndNotDOB =
        duplicationResponse.duplicatedFieldName && duplicationResponse.duplicatedFieldName !== 'date of birth';

      const patientDuplicationInfo = `${patientName} ${
        isDuplicatedFieldNameAndNotDOB
          ? ' (' + duplicationResponse.duplicatedFieldName + '  "' + duplicationResponse.duplicatedFieldValue + '")'
          : ''
      }`;
      const content = (
        <div>
          <p>{patientDuplicationInfo} is an existing patient for the following study sites:</p>
          <ul>
            {studySites.map(item => (
              <li>
                {item.studyName}, {item.siteName}
              </li>
            ))}
          </ul>
          <p>
            Would you like to add the {studyName} study for the {siteName} site to their IntElligo patient record?
          </p>
        </div>
      );

      function showErrorMessage() {
        NotificationManager.error(
          patientName + ' was not added to the ' + studyName + ' study for the ' + siteName + ' site'
        );
      }

      function addNewStudyForPatient() {
        StudyApi.addPatientStudy(patientData)
          .then(() => {
            NotificationManager.success(
              patientName + ' has been added to the ' + studyName + ' study for the ' + siteName + ' site'
            );
            onSave();
          })
          .catch(onRequestError);
      }

      ModalBoxes.confirm({
        content: content,
        title: 'Add Patient Study',
        confirmButton: 'Yes',
        cancelButton: 'No',
        className: 'add-patient-study'
      })
        .then(addNewStudyForPatient)
        .catch(showErrorMessage);
    }

    function showDuplicateEmailModal(message) {
      ModalBoxes.confirm({
        title: 'Duplicate Patient Found',
        content: <>{message}</>,
        cancelButton: 'Cancel',
        confirmButton: false
      }).then(
        () => {},
        () => {}
      );
    }

    const patientData = prepareNewPatientInfoForSave(
      info,
      selectedStudy,
      selectedSite,
      source,
      status,
      medicalRecordNumber
    );
    StudyApi.addPatient(patientData)
      .then(({ data }) => {
        if (data.responsecode === 432) {
          patientData.patientUniqueIdentifier = data.response.patientIdentifier;
          const studySites = data.response.studySites;
          showAddPatientStudyModal(
            patientData,
            selectedStudy.studyName,
            selectedSite.site.siteName,
            studySites,
            data.response
          );
          setLoading(false);
        } else if (data.responsecode === 409) {
          data.response && showDuplicateEmailModal(data.response);
          setLoading(false);
        } else if (data.responsecode === 430) {
          data.response && NotificationManager.warning(data.response);
          setLoading(false);
        } else {
          data.response && NotificationManager.success(data.response);
          onSave();
          return data;
        }
      })
      .catch(error => {
        const errorMessage = error.response.data.message;
        if (errorMessage.includes('PatientPaymentException')) {
          NotificationManager.warning(
            `Patient created successfully. But${errorMessage.substring(errorMessage.indexOf(':') + 1)}`
          );
          onSave();
        } else if (errorMessage.includes('IllegalStateException')) {
          NotificationManager.error(errorMessage.substring(errorMessage.indexOf(':') + 1));
          setLoading(false);
        } else {
          NotificationManager.error(SOMETHING_WENT_WRONG);
          setLoading(false);
        }
      });
  }
}

AddOrEditPatientInfoModal.className = 'add-or-edit-patient-info-modal';
AddOrEditPatientInfoModal.size = 'w1100';

function prepareDictionaries(data) {
  return reduce(
    data,
    function(accumulator, value, key) {
      accumulator[key] = reduce(
        value,
        function(accumulator, value) {
          const disabledOptions = value?.disabledIds || [];
          accumulator[value.id] = {
            ...value,
            disabledIds: !isEmpty(disabledOptions)
              ? zipObject(disabledOptions, Array(disabledOptions.length).fill(true))
              : null
          };
          return accumulator;
        },
        {}
      );
      return accumulator;
    },
    {}
  );
}
