import { cloneDeep, isFunction } from 'lodash/lang';
import moment from 'moment';

import { CLOSED } from '../../../../../../../constants/ssuStatuses';
import { ROLE_SYSTEM_ADMINISTRATOR } from '../../../../../../../constants/userRoles';
import { userHasRole } from '../../../../../../../services/auth';
import { PAYMENT_TYPE_PHYSICAL } from '../../../constants';
import { validateEmail } from '../../patientInfoService';

import { SSN_VALIDATION_MESSAGE } from './FinanceDetails/constants';
import { isValidSsn } from './FinanceDetails/financeDetailsService';

export function parsePatientInfo({
  firstName,
  lastName,
  middleName,
  preferredName,
  dob,
  medicalRecordNumber,
  phonesInfo,
  email,
  address,
  ssn,
  cardInfo,
  guardian,
  sex,
  pronouns,
  isDeceased,
  primaryLanguage,
  isInterestedInFutureResearch,
  isSmsOptIn,
  heightFt,
  heightIn,
  weight,
  races,
  ethnicities,
  interestedInFutureResearch,
  smsOptIn,
  source,
  digitalPaymentAllowed
}) {
  return {
    firstName,
    lastName,
    middleName,
    preferredName,
    dob,
    medicalRecordNumber: medicalRecordNumber || '',
    phonesInfo: {
      alternative: phonesInfo.alternative,
      alternativePhoneType: phonesInfo.alternativePhoneType,
      primary: phonesInfo.primary,
      primaryPhoneType: phonesInfo.primaryPhoneType,
      preferredContactMethod: phonesInfo.preferredContactMethod,
      neverCallAgain: phonesInfo.neverCallAgain,
      voicemailNotAllowed: phonesInfo.voicemailNotAllowed
    },
    email,
    address: {
      addressLine1: address.addressLine1,
      addressLine2: address.addressLine2,
      city: address.city,
      country: address.country,
      state: address.state,
      zipCode: address.zipCode
    },
    ssn,
    cardId: cardInfo?.id,
    paymentType: cardInfo?.paymentType,
    guardianFirstName: guardian?.firstName,
    guardianLastName: guardian?.lastName,
    guardianEmail: guardian?.email,
    guardianPhone: guardian?.phone,
    sex,
    pronouns,
    isDeceased,
    primaryLanguage,
    isInterestedInFutureResearch,
    isSmsOptIn,
    heightFt,
    heightIn,
    weight,
    races,
    ethnicities,
    interestedInFutureResearch,
    smsOptIn,
    source,
    digitalPaymentAllowed: digitalPaymentAllowed
  };
}

export function prepareDataForSave(info) {
  return cloneDeep(info);
}

export function validDob(current) {
  return current.isBefore(moment());
}

export function convertDobToDate(info) {
  // Default to original value if original conditional returns false.
  // This can occur if info.dob has already been converted to a formatted string
  // and the user submits and add patient form more than once due to previous errors
  // and corrections within the form. Would prefer this to be a pure function, but this
  // default value should alleviate the problem.
  info.dob = (info.dob && isFunction(info.dob.format) && info.dob.format('DD/MMM/YYYY')) || info.dob;
}

export function validatePatientInfo(info, isEditMode, study, site, status, source, originalPID) {
  const {
    firstName,
    lastName,
    dob,
    phonesInfo,
    address,
    ssn,
    cardId,
    email,
    guardianPhone,
    guardianEmail,
    heightIn,
    heightFt
  } = info;

  const isUS = address.country === 'US';

  const validationObject = {};
  const requiredFieldMessage = 'Required field';

  if (!isEditMode) {
    if (!study) {
      validationObject.study = requiredFieldMessage;
    }
    if (!site) {
      validationObject.site = requiredFieldMessage;
    } else {
      if (!userHasRole(ROLE_SYSTEM_ADMINISTRATOR) && site.siteStatus === CLOSED) {
        validationObject.site = 'SSU is Closed';
      }
    }

    if (!source) {
      validationObject.source = requiredFieldMessage;
    }
    if (!status) {
      validationObject.status = requiredFieldMessage;
    }
  }

  if (!firstName || firstName.length < 1) {
    validationObject.firstName = requiredFieldMessage;
  }

  if (!lastName || lastName.length < 1) {
    validationObject.lastName = requiredFieldMessage;
  }

  if (!dob) {
    validationObject.dob = requiredFieldMessage;
  }

  if (cardId && cardId.length !== 0 && cardId.length !== 13) {
    validationObject.cardId = `Please fill in the field PID correctly`;
  }

  if (originalPID && cardId.length < 13) {
    validationObject.cardId = `Please fill in the field PID correctly`;
  }

  if ((isEditMode && info?.digitalPaymentAllowed) || (!isEditMode && study?.digitalPatientPayment)) {
    if (!originalPID && info?.paymentType === PAYMENT_TYPE_PHYSICAL && (!cardId || cardId.length === 0)) {
      validationObject.cardId = `The PID field is required for Physical payment type`;
    }
  }

  if (cardId && cardId.length !== 0 && cardId.length === 13) {
    if (!ssn) {
      validationObject.ssn = 'Required with PID';
    }
    if (!phonesInfo?.primary) {
      validationObject['phonesInfo.primary'] = 'Required with PID';
    }
    if (!address?.zipCode) {
      validationObject['address.zipCode'] = 'Required with PID';
    }
    if (!address?.addressLine1) {
      validationObject['address.addressLine1'] = 'Required with PID';
    }
    if (!address?.city) {
      validationObject['address.city'] = 'Required with PID';
    }
    if (!address?.state) {
      validationObject['address.state'] = 'Required with PID';
    }
  }

  if (ssn) {
    if (isUS && !isValidSsn(ssn)) {
      validationObject.ssn = SSN_VALIDATION_MESSAGE;
    } else if (!isUS && ssn.length !== 0 && ssn.length !== 11) {
      validationObject.ssn = 'Please fill in the field SIN correctly';
    }
  }

  if (phonesInfo?.primary && phonesInfo.primary.length !== 0 && phonesInfo.primary.length !== 12) {
    validationObject['phonesInfo.primary'] = `Please fill in the field Phone (primary) correctly`;
  }

  if (phonesInfo?.alternative && phonesInfo.alternative.length !== 0 && phonesInfo.alternative.length !== 12) {
    validationObject['phonesInfo.alternative'] = `Please fill in the field Phone (alternate) correctly`;
  }

  const postalCodeRegex = isUS
    ? /(^\d{5}$)|(^\d{5}-\d{4}$)/
    : /^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z] [0-9][ABCEGHJ-NPRSTV-Z][0-9]$/;

  if (address?.zipCode && address.zipCode.length !== 0 && !address.zipCode.match(postalCodeRegex)) {
    validationObject['address.zipCode'] = `Please fill in the field ${isUS ? 'Zip Code' : 'Postal Code'} correctly`;
  }

  if (email && email.length !== 0 && !validateEmail(email)) {
    validationObject.email = `Please fill in the field Email correctly`;
  }

  if (guardianPhone && guardianPhone.length !== 0 && guardianPhone.length !== 12) {
    validationObject.guardianPhone = `Please fill in the field Guardian Phone correctly`;
  }

  if (guardianEmail && guardianEmail.length !== 0 && !validateEmail(guardianEmail)) {
    validationObject.guardianEmail = `Please fill in the field Guardian Email correctly`;
  }

  if (!heightFt && heightIn !== undefined) {
    validationObject.heightFt = 'Please fill the Height (ft)';
  }

  return validationObject;
}

export function prepareNewPatientInfoForSave(info, selectedStudy, selectedSite, source, status, medicalRecordNumber) {
  const patientInfo = prepareDataForSave(info);

  const studyIdentifier = selectedStudy.uniqueIdentifier;
  const studySiteIdentifier = selectedSite.uniqueIdentifier;
  const patientSourceId = source.id;
  const statusName = status.name;

  const { addressLine1, addressLine2, city, state, country } = patientInfo.address;

  const newPatient = {
    ...patientInfo,
    postalCode: patientInfo.address.zipCode,
    neverCallAgain: patientInfo.phonesInfo.neverCallAgain,
    voicemailNotAllowed: patientInfo.phonesInfo.voicemailNotAllowed,
    primaryContact: patientInfo.phonesInfo.primary || '',
    primaryPhoneType: patientInfo.phonesInfo.primaryPhoneType,
    emergencyContact: patientInfo.phonesInfo.alternative,
    alternativePhoneType: patientInfo.phonesInfo.alternativePhoneType,
    preferredContactMethod: patientInfo.phonesInfo.preferredContactMethod,
    addressLine1,
    addressLine2,
    city,
    state,
    country,
    studyIdentifier,
    studySiteIdentifier,
    patientSourceId,
    statusName,
    medicalRecordNumber,
    email: patientInfo.email || '',
    statusChangeLocation: 'ADD_PATIENT',
    digitalPaymentAllowed: selectedStudy?.digitalPatientPayment
  };

  delete newPatient.address;
  delete newPatient.phonesInfo;

  return newPatient;
}

export function preparePatientInfoForAssign(info, currentStudy) {
  const patientInfo = prepareDataForSave(info);

  const studyIdentifier = currentStudy.studyId;
  const studySiteIdentifier = currentStudy.ssuId;
  const patientSourceId = currentStudy.patientSourceId;
  const statusName = 'Identified';
  const email = patientInfo.email || '';

  const patient = {
    ...patientInfo,
    patientUniqueIdentifier: info.uniqueIdentifier,
    studyIdentifier,
    studySiteIdentifier,
    patientSourceId,
    statusName,
    email,
    statusChangeLocation: 'PATIENT_INFO'
  };

  delete patient.patientId;

  return patient;
}
