import { useEffect, useMemo, useState } from 'react';
import { cloneDeep, isString } from 'lodash/lang';
import { omit, set } from 'lodash/object';

import {
  normalizeCardId,
  normalizePhoneNumber,
  normalizeSin,
  normalizeSsn,
  normalizeZipCode
} from '../../../../../../../services/normalizers';
import { PAYMENT_TYPE_DIGITAL } from '../../../constants';

import { parsePatientInfo } from './AddOrEditPatientInfoModalService';

export function useInfo(patientInfo) {
  const [validationMessages, setValidationMessages] = useState({});

  const parsedPatientInfo = useMemo(
    function() {
      if (!patientInfo) {
        return {
          firstName: '',
          lastName: '',
          middleName: '',
          preferredName: '',
          dob: '',
          phonesInfo: {
            alternative: '',
            alternativePhoneType: '',
            primary: '',
            primaryPhoneType: '',
            preferredContactMethod: '',
            neverCallAgain: false,
            voicemailNotAllowed: false
          },
          email: '',
          address: { country: 'US', addressLine1: '', addressLine2: '', city: '', state: '', zipCode: '' },
          ssn: '',
          cardId: '',
          guardianFirstName: '',
          guardianLastName: '',
          guardianEmail: '',
          guardianPhone: '',
          sex: '',
          pronouns: '',
          isDeceased: false,
          primaryLanguage: '',
          smsOptIn: 'NOT_ASKED',
          interestedInFutureResearch: 'NOT_ASKED',
          heightFt: '',
          heightIn: '',
          weight: '',
          races: [],
          ethnicities: [],
          digitalPaymentAllowed: false,
          paymentType: null
        };
      }
      return parsePatientInfo(patientInfo);
    },
    [patientInfo]
  );

  const [info, setInfo] = useState(parsedPatientInfo);

  const isEditMode = useMemo(
    function() {
      return !!patientInfo?.id;
    },
    [patientInfo]
  );

  function handleCountryChange(state) {
    if (state?.id === info.address?.country) {
      return;
    }
    setInfo(function(info) {
      return {
        ...info,
        ssn: '',
        cardId: '',
        address: {
          ...info.address,
          country: state?.id,
          state: '',
          zipCode: ''
        }
      };
    });
  }

  function handleStateChange(state, name) {
    resetValidationMessageByName(name);

    setInfo(function(info) {
      return {
        ...info,
        address: {
          ...info.address,
          state: state?.id
        }
      };
    });
  }

  function handleSexChange(sex) {
    setInfo(function(info) {
      return {
        ...info,
        sex: sex?.id
      };
    });
  }

  function handlePronounsChange(pronouns) {
    setInfo(function(info) {
      return {
        ...info,
        pronouns: pronouns?.id
      };
    });
  }

  function handlePrimaryLanguageChange(language) {
    setInfo(function(info) {
      return {
        ...info,
        primaryLanguage: language?.id
      };
    });
  }

  function handlePreferredContactMethodChange(contactMethod) {
    setInfo(function(info) {
      return {
        ...info,
        phonesInfo: {
          ...info.phonesInfo,
          preferredContactMethod: contactMethod?.id
        }
      };
    });
  }

  function handleSelectedPhoneTypeChange(phoneType, phoneKey) {
    setInfo(function(info) {
      return {
        ...info,
        phonesInfo: {
          ...info.phonesInfo,
          [phoneKey]: phoneType?.target.value
        }
      };
    });
  }

  function handleHeightChange(height, heightKey) {
    setInfo(function(info) {
      return {
        ...info,
        [heightKey]: height?.value
      };
    });
  }

  function handleRacesChange(races) {
    setInfo(function(info) {
      return {
        ...info,
        races: [...races].map(race => race.id)
      };
    });
  }

  function handleEthnicitiesChange(ethnicities) {
    setInfo(function(info) {
      return {
        ...info,
        ethnicities: [...ethnicities].map(ethnicity => ethnicity.id)
      };
    });
  }

  function handleDateOfBirthChange(dob, name) {
    resetValidationMessageByName(name);

    setInfo(function(info) {
      return {
        ...info,
        dob
      };
    });
  }

  function handleInputChange({ target: { type, checked, value: inputValue, name } }) {
    const value = type === 'checkbox' ? checked : inputValue;

    resetValidationMessageByName(name);

    setInfo(function(info) {
      const cloned = cloneDeep(info);
      const isUS = info.address.country === 'US';
      if (['phonesInfo.primary', 'phonesInfo.alternative', 'guardianPhone'].includes(name)) {
        return set(cloned, name, normalizePhoneNumber(value));
      }

      if (name === 'cardId') {
        return set(cloned, name, normalizeCardId(value));
      }

      if (name === 'ssn') {
        return set(cloned, name, isUS ? normalizeSsn(value) : normalizeSin(value));
      }

      if (name === 'address.zipCode') {
        return set(cloned, name, normalizeZipCode(value, isUS));
      }

      return set(cloned, name, value);
    });
  }

  function resetValidationMessageByName(name) {
    if (validationMessages[name]) {
      setValidationMessages(function(state) {
        return omit(state, name);
      });
    }
  }
  function handleChangeSmsOptInDropdown(option) {
    if (option && option.id !== info.smsOptIn) {
      setInfo(function(info) {
        return {
          ...info,
          smsOptIn: option.id
        };
      });
    }
  }

  function handlePaymentTypeChange(value) {
    setInfo(function(info) {
      return !value || value === PAYMENT_TYPE_DIGITAL
        ? {
            ...info,
            paymentType: value,
            cardId: null
          }
        : {
            ...info,
            paymentType: value
          };
    });
  }

  function handleChangeInterestedInFutureResearch(option) {
    if (option && option.id !== info.interestedInFutureResearch) {
      setInfo(function(info) {
        return {
          ...info,
          interestedInFutureResearch: option.id
        };
      });
    }
  }

  return {
    info,
    isEditMode,
    validationMessages,
    setValidationMessages,
    handleCountryChange,
    handleStateChange,
    handleDateOfBirthChange,
    handleInputChange,
    handleSexChange,
    handlePronounsChange,
    handlePrimaryLanguageChange,
    handlePreferredContactMethodChange,
    handleSelectedPhoneTypeChange,
    handleHeightChange,
    handleRacesChange,
    handleEthnicitiesChange,
    resetValidationMessageByName,
    handleChangeSmsOptInDropdown,
    handleChangeInterestedInFutureResearch,
    handlePaymentTypeChange
  };
}

export function useRequiredFields(cardId) {
  const [requiredFields, setRequiredFields] = useState({
    firstName: true,
    lastName: true,
    dob: true,
    'address.country': true
  });

  useEffect(
    function() {
      const req = isString(cardId) && cardId.length > 0;
      setRequiredFields(state => ({
        ...state,
        'phonesInfo.primary': req,
        'address.zipCode': req,
        ssn: req,
        'address.addressLine1': req,
        'address.city': req,
        'address.state': req
      }));
    },
    [cardId]
  );

  function isRequiredField(field) {
    return !!requiredFields[field];
  }

  return [isRequiredField];
}
