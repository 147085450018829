import React, { useEffect, useRef, useState } from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { isFunction } from 'lodash/lang';

import Input from '../../../../../../../../common/data-entry/Input';
import useOutsideClickDetector from '../../../../../../../../common/hooks/useOutsideClickDetector';
import { PAYMENT_TYPE_DIGITAL, PAYMENT_TYPE_PHYSICAL } from '../../../../constants';

import { SSN_RULE_MESSAGE, SSN_VALIDATION_MESSAGE } from './constants';
import { isValidSsn } from './financeDetailsService';

import './FinanceDetails.scss';

export default function FinanceDetails({
  handleInputChange,
  isRequiredField,
  getValidationMessage,
  info,
  userCanManageFinanceDetails,
  isEditMode,
  selectedStudy,
  handlePaymentTypeChange,
  paymentType
}) {
  const [showDialog, setShowDialog] = useState(false);
  const [showValidationMessage, setShowValidationMessage] = useState(false);

  const [physicalChecked, setPhysicalChecked] = useState(paymentType === PAYMENT_TYPE_PHYSICAL);
  const [digitalChecked, setDigitalChecked] = useState(paymentType === PAYMENT_TYPE_DIGITAL);

  const { ssn, cardId, address } = info;
  const isUS = address.country === 'US';

  const dialogRef = useRef();
  useOutsideClickDetector(dialogRef, () => {
    setShowDialog(false);
  });

  useEffect(() => {
    if (ssn && isUS && !isValidSsn(ssn)) {
      setShowDialog(true);
      setShowValidationMessage(true);
    } else {
      setShowDialog(false);
      setShowValidationMessage(false);
    }
  }, [ssn, isUS]);

  const resolvePaymentTypesVisibility = () => {
    if (!isEditMode) {
      return selectedStudy?.digitalPatientPayment && <PaymentTypesFormTooltip />;
    } else {
      return info?.digitalPaymentAllowed && <PaymentTypesFormTooltip />;
    }
  };

  const onPaymentTypeChange = event => {
    if (event.target.value === info.paymentType) {
      setPhysicalChecked(false);
      setDigitalChecked(false);
      handlePaymentTypeChange(null);
      return;
    }
    if (event.target.value === PAYMENT_TYPE_PHYSICAL) {
      setPhysicalChecked(true);
      setDigitalChecked(false);
    } else {
      setPhysicalChecked(false);
      setDigitalChecked(true);
    }
    isFunction(handlePaymentTypeChange) && handlePaymentTypeChange(event.target.value);
  };

  const arePaymentTypeCheckboxesDisabled = () => {
    return paymentType;
  };

  const PaymentTypesFormTooltip = () => {
    const radiobuttonFontsConfig = {
      '& .MuiFormControlLabel-label': {
        fontSize: '15px'
      }
    };
    return arePaymentTypeCheckboxesDisabled() ? (
      <Tooltip title="Once Payment Type is selected, it cannot be changed" placement="top">
        <FormGroup row onChange={event => onPaymentTypeChange(event)}>
          <FormControlLabel
            control={<Checkbox checked={physicalChecked} />}
            label="Physical"
            value={PAYMENT_TYPE_PHYSICAL}
            sx={radiobuttonFontsConfig}
            disabled={arePaymentTypeCheckboxesDisabled()}
          />
          <FormControlLabel
            control={<Checkbox checked={digitalChecked} />}
            label="Digital"
            value={PAYMENT_TYPE_DIGITAL}
            sx={radiobuttonFontsConfig}
            disabled={arePaymentTypeCheckboxesDisabled()}
          />
        </FormGroup>
      </Tooltip>
    ) : (
      <FormGroup row onChange={event => onPaymentTypeChange(event)}>
        <FormControlLabel
          control={<Checkbox checked={physicalChecked} />}
          label="Physical"
          value={PAYMENT_TYPE_PHYSICAL}
          sx={radiobuttonFontsConfig}
          disabled={arePaymentTypeCheckboxesDisabled()}
        />
        <FormControlLabel
          control={<Checkbox checked={digitalChecked} />}
          label="Digital"
          value={PAYMENT_TYPE_DIGITAL}
          sx={radiobuttonFontsConfig}
          disabled={arePaymentTypeCheckboxesDisabled()}
        />
      </FormGroup>
    );
  };

  const paymentTypeNotPhysicalOrEmpty = () => {
    return !info?.paymentType || info?.paymentType !== PAYMENT_TYPE_PHYSICAL;
  };

  return (
    <div className="row">
      <div className="col-12 apm-form-label">
        <div className="edit-patient-modal-finance-details-header">Finance Details</div>
        {resolvePaymentTypesVisibility()}
      </div>
      <div className="col-4">
        <Input
          label={isUS ? 'SSN' : 'SIN'}
          name="ssn"
          value={ssn}
          onChange={handleInputChange}
          required={isRequiredField('ssn')}
          validationMessage={showValidationMessage ? SSN_VALIDATION_MESSAGE : getValidationMessage('ssn')}
          disabled={!userCanManageFinanceDetails}
        />
        {showDialog && (
          <div ref={dialogRef} className={'dropdown-menu-wrapper'}>
            <div className={'message'}>{SSN_RULE_MESSAGE}</div>
          </div>
        )}
      </div>
      <div className="col-4">
        <Input
          disabled={
            !isUS ||
            !userCanManageFinanceDetails ||
            (selectedStudy?.digitalPatientPayment && paymentTypeNotPhysicalOrEmpty()) ||
            (info?.digitalPaymentAllowed && paymentTypeNotPhysicalOrEmpty())
          }
          label="PID"
          name="cardId"
          value={cardId}
          onChange={handleInputChange}
          validationMessage={getValidationMessage('cardId')}
        />
      </div>
    </div>
  );
}
