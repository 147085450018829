import React, { useCallback, useContext, useMemo, useState } from 'react';
import moment from 'moment';

import { DD_SLASH_MMM_SLASH_YYYY } from '../../../../../constants/dateFormat';
import { MANAGE_SITE_PAYMENTS } from '../../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../../services/auth';
import { toBill } from '../../../../../services/financial';
import {
  getDaysOpen,
  isOpenStatus,
  isPaidStatus,
  isPartialStatus,
  isPastDueStatus
} from '../../NewInvoice/InvoiceTable/InvoiceTableService';
import {
  ACTIONS_BUTTON,
  COMPLETE,
  OPEN,
  PAID,
  PARTIAL,
  PAST_DUE,
  TABLE_CHECKBOX
} from '../../NewInvoice/InvoiceTable/TableConstants';
import { Amount } from '../../shared/amount-view/Amount/Amount';
import { DefaultFinanceCell } from '../../shared/FinanseTableMUI/DefaultFinanceCell';
import { FinanceTableMUI } from '../../shared/FinanseTableMUI/FinanceTableMUI';
import { formatDateForCSV, prepareNameForCSV } from '../../shared/FinanseTableMUI/FinanceTableServices';
import { SitePaymentsContext } from '../SitePaymentsContext';
import { eventNumberComparator, resolveEventSuffix } from '../SitePaymentServices';

import { BillNumberCell } from './BillNumberCell/BillNumberCell';
import { SelectedItemsMenu } from './SelectedItemsMenu/SelectedItemsMenu';
import { SitePaymentsTableItemMenu } from './SitePaymentsTableItemMenu/SitePaymentsTableItemMenu';
import { PaymentStatusIcon, SiteApprovalStatusIcon, SitePaymentStatusIcon, StatusCell } from './StatusCell/StatusCell';
import {
  SITE_CREDIT,
  SITE_DEBIT,
  SITE_PAYMENTS_COLUMNS_HIDDEN_BY_DEFAULT,
  SiteApprovalStatus,
  SitePaymentStatus
} from './SitePaymentsConstants';

export function SitePaymentsTable() {
  const { tableData = [], checkedEvents, setCheckedEvents, firstLoading } = useContext(SitePaymentsContext);

  const stateFromLocalStorage = JSON.parse(localStorage?.getItem('SITE_PAYMENTS_TABLE_CONFIGURATION'));
  const [pinnedColumns, setPinnedColumns] = useState(
    stateFromLocalStorage?.pinnedColumns || {
      left: [TABLE_CHECKBOX],
      right: [ACTIONS_BUTTON]
    }
  );

  const handlePinnedColumnsChange = useCallback(updatedPinnedColumns => {
    setPinnedColumns({
      ...updatedPinnedColumns,
      left: [TABLE_CHECKBOX, ...updatedPinnedColumns.left.filter(column => column !== TABLE_CHECKBOX)],
      right: [...updatedPinnedColumns.right.filter(column => column !== ACTIONS_BUTTON), ACTIONS_BUTTON]
    });
  }, []);

  const getTogglableColumns = useCallback(
    columns =>
      columns.filter(column => ![ACTIONS_BUTTON, TABLE_CHECKBOX].includes(column.field)).map(column => column.field),
    []
  );

  const columns = useMemo(() => {
    const columns = [
      {
        field: 'eventNumber',
        headerName: 'Event ID',
        flex: 1,
        minWidth: 125,
        sortComparator: eventNumberComparator,
        valueGetter: ({ row }) => {
          if (row.adjustmentSequence === 0) {
            return resolveEventSuffix(row.eventNumber, row.sitePaymentType);
          }
          return resolveEventSuffix(`${row.eventNumber}.${row.adjustmentSequence}`, row.sitePaymentType);
        }
      },
      {
        field: 'eventName',
        headerName: 'Event Name',
        flex: 1,
        minWidth: 170,
        renderCell: DefaultFinanceCell,
        valueFormatter: ({ value }) => prepareNameForCSV(value)
      },
      {
        field: 'revenueDate',
        headerName: 'Revenue Date',
        flex: 1,
        minWidth: 160,
        type: 'date',
        valueFormatter: ({ value }) => (value ? moment(value).format(DD_SLASH_MMM_SLASH_YYYY) : '-'),
        valueGetter: ({ value }) => value && new Date(value),
        renderCell: ({ row: { revenueDate } }) => (
          <div>{revenueDate ? moment(revenueDate).format(DD_SLASH_MMM_SLASH_YYYY) : '—'}</div>
        )
      },
      {
        field: 'invoiceDate',
        headerName: 'Invoice Date',
        flex: 1,
        minWidth: 160,
        type: 'date',
        valueFormatter: ({ value }) => (value ? moment(value).format(DD_SLASH_MMM_SLASH_YYYY) : '-'),
        valueGetter: ({ value }) => value && new Date(value),
        renderCell: ({ row: { invoiceDate } }) => (
          <div>{invoiceDate ? moment(invoiceDate).format(DD_SLASH_MMM_SLASH_YYYY) : '—'}</div>
        )
      },
      {
        field: 'invoiceAmount',
        headerName: 'Invoice Amount',
        flex: 1,
        minWidth: 170,
        type: 'number',
        valueGetter: ({ row }) => toBill(row.invoiceAmount),
        renderCell: ({ row }) =>
          row.adjustmentSequence !== 0 ? '—' : <Amount coinsAmount={row.invoiceAmount} showDollarSign />
      },
      {
        field: 'paymentStatus',
        headerName: 'Payment Status',
        flex: 1,
        minWidth: 170,
        valueGetter: ({ row }) => {
          const modifiedRow = {
            ...row,
            amountPaid: row.reconcileTotalAmountPaid
          };
          if (modifiedRow.adjustmentSequence !== 0) return '—';
          else if (isCompleteStatus(modifiedRow)) return COMPLETE;
          else if (isOpenStatus(modifiedRow)) return OPEN;
          else if (isPastDueStatus(modifiedRow)) return PAST_DUE;
          else if (isPartialStatus(modifiedRow)) return PARTIAL;
          else if (isPaidStatus(modifiedRow)) return PAID;
          return '';
        },
        valueFormatter: ({ value }) => prepareNameForCSV(value),
        renderCell: ({ value }) => (
          <StatusCell value={value} statusWidth="80px">
            <PaymentStatusIcon status={value} />
          </StatusCell>
        )
      },
      {
        field: 'siteAmount',
        headerName: 'Site Amount',
        flex: 1,
        minWidth: 150,
        type: 'number',
        valueGetter: ({ row }) => toBill(row.siteAmount),
        renderCell: ({ row }) => <Amount coinsAmount={row.siteAmount} showDollarSign />
      },
      {
        field: 'sitePaidAmount',
        headerName: 'Site Paid Amount',
        flex: 1,
        minWidth: 180,
        type: 'number',
        valueGetter: ({ row }) => toBill(row.sitePaidAmount),
        renderCell: ({ row }) => <Amount coinsAmount={row.sitePaidAmount} showDollarSign />
      },
      {
        field: 'siteApprovalStatus',
        headerName: 'Site Approval Status',
        flex: 1,
        minWidth: 170,
        valueGetter: ({ row }) => SiteApprovalStatus[row.siteApprovalStatus],
        renderCell: ({ value }) => (
          <StatusCell value={value} statusWidth="140px">
            <SiteApprovalStatusIcon status={value} />
          </StatusCell>
        )
      },
      {
        field: 'siteApprovalDate',
        headerName: 'Site Approval Date',
        flex: 1,
        minWidth: 160,
        type: 'date',
        valueFormatter: ({ value }) => (value ? moment(value).format(DD_SLASH_MMM_SLASH_YYYY) : '-'),
        valueGetter: ({ value }) => value && new Date(value),
        renderCell: ({ row: { siteApprovalDate } }) => (
          <div>{siteApprovalDate ? moment(siteApprovalDate).format(DD_SLASH_MMM_SLASH_YYYY) : '—'}</div>
        )
      },
      {
        field: 'sitePaidVariance',
        headerName: 'Site Paid Variance',
        flex: 1,
        minWidth: 170,
        type: 'number',
        valueGetter: ({ row }) => {
          return `${toBill(row.sitePaidAmount - row.siteAmount)}`;
        },
        renderCell: ({ row }) => {
          return <Amount coinsAmount={row.sitePaidAmount - row.siteAmount} showDollarSign />;
        }
      },
      {
        field: 'sitePaymentId',
        headerName: 'Site Payment ID',
        flex: 1,
        minWidth: 150,
        renderCell: DefaultFinanceCell
      },
      {
        field: 'sitePaymentStatus',
        headerName: 'Site Payment Status',
        flex: 1,
        minWidth: 170,
        valueGetter: ({ row }) => SitePaymentStatus[row.sitePaymentStatus],
        renderCell: ({ value }) => (
          <StatusCell value={value} statusWidth="80px">
            <SitePaymentStatusIcon status={value} />
          </StatusCell>
        )
      },
      {
        field: 'sitePaymentBillNumber',
        headerName: 'Bill #',
        flex: 1,
        minWidth: 180,
        renderCell: ({
          row: {
            sitePaymentBillNumber,
            itemSiteId,
            sitePaymentType,
            studyId,
            siteId,
            eventNumber,
            siteBillExportDate,
            siteApprovalStatus
          }
        }) => {
          return sitePaymentBillNumber ? (
            <BillNumberCell
              sitePaymentBillNumber={sitePaymentBillNumber}
              itemSiteId={itemSiteId}
              sitePaymentType={sitePaymentType}
              siteId={siteId}
              studyId={studyId}
              eventNumber={eventNumber}
              siteBillExportDate={siteBillExportDate}
              siteApprovalStatus={siteApprovalStatus}
            />
          ) : (
            '—'
          );
        }
      },
      {
        field: 'siteBillDate',
        headerName: 'Bill Date',
        minWidth: 140,
        type: 'date',
        valueFormatter: ({ value }) => (value ? moment(value).format(DD_SLASH_MMM_SLASH_YYYY) : '-'),
        valueGetter: ({ value }) => value && new Date(value),
        renderCell: ({ row: { siteBillDate } }) => (
          <div>{siteBillDate ? moment(siteBillDate).format(DD_SLASH_MMM_SLASH_YYYY) : '—'}</div>
        )
      },
      {
        field: 'studyName',
        headerName: 'Study',
        flex: 1,
        minWidth: 110,
        renderCell: DefaultFinanceCell,
        valueFormatter: ({ value }) => prepareNameForCSV(value)
      },
      {
        field: 'pcn',
        headerName: 'PCN',
        flex: 1,
        minWidth: 100,
        renderCell: DefaultFinanceCell
      },
      {
        field: 'siteName',
        headerName: 'Site',
        flex: 1,
        minWidth: 100,
        renderCell: DefaultFinanceCell,
        valueFormatter: ({ value }) => prepareNameForCSV(value)
      },
      {
        field: 'siteNumber',
        headerName: 'Site Number',
        flex: 1,
        minWidth: 150,
        renderCell: DefaultFinanceCell,
        valueFormatter: ({ value }) => (value != null ? `[${value}]` : '')
      },
      {
        field: 'clientName',
        headerName: 'Client',
        flex: 1,
        minWidth: 150,
        renderCell: DefaultFinanceCell,
        valueFormatter: ({ value }) => prepareNameForCSV(value)
      },
      {
        field: 'budgetEventType',
        headerName: 'Budget Event Type',
        flex: 1,
        minWidth: 145,
        renderCell: DefaultFinanceCell
      },
      {
        field: 'epochName',
        headerName: 'Epoch',
        flex: 1,
        minWidth: 145,
        renderCell: DefaultFinanceCell,
        valueFormatter: ({ value }) => prepareNameForCSV(value)
      },
      {
        field: 'encounterName',
        headerName: 'Encounter',
        flex: 1,
        minWidth: 135,
        renderCell: DefaultFinanceCell,
        valueFormatter: ({ value }) => prepareNameForCSV(value)
      },
      {
        field: 'itemGroupName',
        headerName: 'Item Group',
        flex: 1,
        minWidth: 135,
        renderCell: DefaultFinanceCell,
        valueFormatter: ({ value }) => prepareNameForCSV(value)
      },
      {
        field: 'eventDate',
        headerName: 'Event Date',
        flex: 1,
        minWidth: 150,
        type: 'date',
        valueFormatter: ({ value }) => (value ? moment(value).format(DD_SLASH_MMM_SLASH_YYYY) : '-'),
        valueGetter: ({ value }) => value && new Date(value),
        renderCell: ({ row }) => {
          const content = moment(row.eventDate).format(DD_SLASH_MMM_SLASH_YYYY);
          return (
            <div className="MuiDataGrid-cellContent" title={content}>
              {content}
            </div>
          );
        }
      },
      {
        field: 'invoiceNumber',
        headerName: 'Invoice',
        flex: 1,
        minWidth: 175,
        sortComparator: eventNumberComparator,
        valueGetter: ({ row: { invoiceNumber, sitePaymentType } }) =>
          invoiceNumber ? resolveEventSuffix(invoiceNumber, sitePaymentType) : '',
        renderCell: DefaultFinanceCell
      },
      {
        field: 'reconcileDepositNumber',
        headerName: 'Deposit Number',
        flex: 1,
        minWidth: 175,
        renderCell: DefaultFinanceCell
      },
      {
        field: 'reconcileDaysOpen',
        headerName: 'Days Open',
        flex: 1,
        minWidth: 175,
        valueGetter: ({ row }) => {
          return getDaysOpen({
            ...row,
            amountPaid: row.reconcileTotalAmountPaid,
            invoiceDate: row.invoiceDate ? moment(row.invoiceDate) : moment(0)
          });
        },
        renderCell: DefaultFinanceCell
      },
      {
        field: 'reconcileTotalAmountPaid',
        headerName: 'Amount Paid',
        flex: 1,
        minWidth: 170,
        type: 'number',
        valueGetter: ({ row }) =>
          row.adjustmentSequence !== 0 || (isCompleteStatus(row) && row.reconcileTotalAmountPaid === 0)
            ? '-'
            : toBill(row.reconcileTotalAmountPaid),
        renderCell: ({ row }) =>
          row.adjustmentSequence !== 0 ? (
            '—'
          ) : isCompleteStatus(row) && row.reconcileTotalAmountPaid === 0 ? (
            <div>N/A</div>
          ) : (
            <Amount coinsAmount={row.reconcileTotalAmountPaid} showDollarSign />
          )
      },
      {
        field: 'reconcilePostedDate',
        headerName: 'Date Posted',
        flex: 1,
        minWidth: 160,
        type: 'date',
        valueFormatter: ({ value }) => formatDateForCSV(value),
        valueGetter: ({ value }) => value && new Date(value),
        renderCell: ({ row: { reconcilePostedDate } }) => (
          <div>{reconcilePostedDate ? moment.utc(reconcilePostedDate).format(DD_SLASH_MMM_SLASH_YYYY) : '—'}</div>
        )
      },
      {
        field: 'reconcileVariance',
        headerName: 'Variance',
        flex: 1,
        minWidth: 170,
        type: 'number',
        valueGetter: ({ row }) => {
          return isCompleteStatus(row) && row.reconcileTotalAmountPaid === 0
            ? `${toBill(0)}`
            : `${toBill(row.reconcileTotalAmountPaid - row.invoiceAmount)}`;
        },
        renderCell: ({ row }) => {
          return row.adjustmentSequence !== 0 ? (
            '—'
          ) : (
            <Amount
              className="MuiDataGrid-cellContent"
              showDollarSign
              coinsAmount={
                isCompleteStatus(row) && row.reconcileTotalAmountPaid === 0
                  ? 0
                  : row.reconcileTotalAmountPaid - row.invoiceAmount
              }
            />
          );
        }
      },
      {
        field: 'investigator',
        headerName: 'Investigator',
        flex: 1,
        minWidth: 180,
        renderCell: DefaultFinanceCell
      },
      {
        field: 'patientId',
        headerName: 'Patient ID',
        flex: 1,
        minWidth: 135,
        renderCell: DefaultFinanceCell
      },
      {
        field: 'subjectId',
        headerName: 'Subject ID',
        flex: 1,
        minWidth: 145,
        renderCell: DefaultFinanceCell,
        valueFormatter: ({ value }) => (value != null ? `[${value}]` : '')
      },
      {
        field: 'sitePaymentDate',
        headerName: 'Site Payment Date',
        flex: 1,
        minWidth: 160,
        type: 'date',
        valueFormatter: sitePaymentsDateValueFormatter,
        valueGetter: sitePaymentsValueGetter,
        renderCell: sitePaymentsDateRenderCell
      },
      {
        field: 'adjustmentMemo',
        headerName: 'Adjustment Memo',
        flex: 1,
        minWidth: 175,
        renderCell: DefaultFinanceCell
      },
      {
        field: 'currentPatientStatus',
        headerName: 'Current Patient Status',
        flex: 1,
        minWidth: 155,
        renderCell: DefaultFinanceCell
      },
      {
        field: 'sitePaymentComment',
        headerName: 'Site Payment Comment',
        flex: 1,
        minWidth: 155,
        renderCell: DefaultFinanceCell
      },
      {
        field: 'siteBillExportDate',
        headerName: 'Export Date',
        flex: 1,
        minWidth: 155,
        type: 'date',
        valueFormatter: ({ value }) => (value ? moment(value).format(DD_SLASH_MMM_SLASH_YYYY) : '-'),
        valueGetter: ({ value }) => value && new Date(value),
        renderCell: ({ row: { siteBillExportDate } }) => (
          <div>{siteBillExportDate ? moment(siteBillExportDate).format(DD_SLASH_MMM_SLASH_YYYY) : '—'}</div>
        )
      },
      {
        field: 'nonInvoiceable',
        headerName: 'Non-Invoiceable',
        minWidth: 180,
        valueGetter: ({ value }) => (value ? 'Yes' : 'No')
      }
    ];

    const actionColumn = {
      field: 'actions',
      type: 'actions',
      resizable: false,
      width: 50,
      renderCell: ({ row }) => <SitePaymentsTableItemMenu row={row} />
    };
    return userHasAccessTo(MANAGE_SITE_PAYMENTS) ? [...columns, actionColumn] : columns;
  }, []);

  return (
    <>
      <SelectedItemsMenu />
      <FinanceTableMUI
        localStorageTableName="SITE_PAYMENTS"
        getRowId={row => row.itemSiteId}
        data-testid="site-payments-table"
        rows={tableData}
        columns={columns}
        checkboxSelection
        disableRowSelectionOnClick
        getTogglableColumns={getTogglableColumns}
        footerElementsCount={tableData.length}
        initialState={{
          columns: {
            columnVisibilityModel: SITE_PAYMENTS_COLUMNS_HIDDEN_BY_DEFAULT
          },
          pinnedColumns
        }}
        pinnedColumns={pinnedColumns}
        onPinnedColumnsChange={handlePinnedColumnsChange}
        onRowSelectionModelChange={newRowSelectionModel => {
          setCheckedEvents(newRowSelectionModel);
        }}
        rowSelectionModel={checkedEvents}
        localeText={{ noRowsLabel: firstLoading ? 'Make selections to display results' : 'No results to display' }}
        columnsFilterSort="asc"
        tableVersion="1.04"
      />
    </>
  );
}

function isCompleteStatus({ sitePaymentType }) {
  return sitePaymentType === SITE_CREDIT || sitePaymentType === SITE_DEBIT;
}

export const sitePaymentsDateValueFormatter = ({ value }) =>
  value ? moment.utc(value).format(DD_SLASH_MMM_SLASH_YYYY) : '-';

export const sitePaymentsDateRenderCell = ({ row: { sitePaymentDate } }) => (
  <div>{sitePaymentDate ? moment.utc(sitePaymentDate).format(DD_SLASH_MMM_SLASH_YYYY) : '—'}</div>
);

export const sitePaymentsValueGetter = ({ value }) => value && new Date(value);
